body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.create-page.company-informations {
  padding: 5px 8px 0 8px !important;
}

.edit-page.company-informations {
  padding: 0 !important;
}

.company-informations .MuiCard-root {
  box-shadow: none;
  background-color: transparent;
}
.company-informations .MuiPaper-root {
  overflow: visible;
}

.customer-edit-panel .MuiBox-root {
  padding-top: 0 !important;
}

.edit-user .MuiPaper-root {
  box-shadow: none;
}

.MuiDrawer-docked-RaSidebar-root .MuiPaper-root {
  width: 250px !important;
}
.category-list-hidden {
  display: none;
}

.categories-bundled:hover + .category-list-hidden {
  display: block;
}

.custom-link {
  text-decoration: none;
  color: #22997b;
}

.toolbar-inset {
  margin-left: 1rem !important;
}

.form-css .MuiAutocomplete-popperDisablePortal,
.form-css .MuiAutocomplete-endAdornment {
  display: none !important;
}

/* .form-css .MuiAutocomplete-endAdornment {


    display: none !important;

  } */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ProseMirror:hover {
  background-color: transparent !important;
  border: 1px solid black !important;
}

.ProseMirror:focus {
  border: 2px solid #2a9d8f !important;
  outline: none;
}

.RaRichTextInputToolbar-root {
  display: none !important;
}
.media-item-description > #description-label,
.custom-rich-text-input #description-label {
  width: 100% !important;
}
