.Toastify__toast-container {
  width: fit-content;
}

.Toastify__toast {
  padding: 0;
}

.Toastify__toast-body {
  padding: 0;
}
