.notification {
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  padding: 1rem;
  max-height: 100px;
  border-radius: 3px;
  opacity: 0.9;
  height: 96px;
  box-sizing: border-box;
  display: flex;
  align-items: start;
  width: 400px;
}

.notification:hover {
  opacity: 1;
}

.notification-title {
  font-weight: 600;
  font-size: 1rem;
  text-align: left;
  margin: 0.25rem 0;
  width: 300px;
}

.notification-message {
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  text-align: left;
  margin-left: -1px;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  opacity: 0.6;
}

.notification-image {
  margin-right: 0.75rem;
  height: 100%;
}

.close-button {
  padding: 0;
  margin-left: 1rem;
  order: 3;
  border: unset;
  cursor: pointer;
  background-color: transparent;
}

.close-button-icon {
  margin-left: -6px;
}
